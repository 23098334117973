<template>
  <div class="danger-form-page" v-loading="pageload">
    <el-form ref="form" class="geology-form" :rules="formRules" :model="form" label-width="120px">
      <el-tabs class="geology-tabs" v-model="activeTabName" style="margin-bottom: 20px">
        <el-tab-pane label="基本信息" name="tabBase">
          <el-divider content-position="left">基本信息</el-divider>
          <el-row>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测点名称" prop="DangerName">
                <el-input v-model="form.DangerName" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="监测点编号" prop="DangerCode">
                <el-input v-model="form.DangerCode" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="灾害类型" prop="DangerType">
                <el-select v-model="form.DangerType" size="small" placeholder="请选择" style="width: 100%">
                  <el-option v-for="item in DangerTypeData" :key="item.Id" :label="item.ItemName" :value="item.ItemValue">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="灾害等级" prop="DangerLevel">
                <el-select v-model="form.DangerLevel" size="small" placeholder="请选择" style="width: 100%">
                  <el-option v-for="item in DangerLevelData" :key="item.Id" :label="item.ItemName"
                    :value="item.ItemValue">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="岩性">
                <el-input v-model="form.RockType" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="高程">
                <el-input v-model="form.Height" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="坡向">
                <el-input v-model="form.DangerAspect" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="坡度">
                <el-input v-model="form.DangerSlope" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="威胁人数">
                <el-input v-model="form.DangerPeople" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="威胁房屋">
                <el-input v-model="form.DangerHouse" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="19" :offset="1">
              <el-form-item label="威胁财产">
                <el-input v-model="form.DangerProperty" size="small"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="9" :offset="1">
              <el-form-item label="地址">
                <el-cascader size="small" :options="options" v-model="regionValue" placeholder="请选择地区" style="width: 100%"
                  @change="regionHandleChange">
                </el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-col :span="11" :offset="1">
                <el-form-item label-width="0px">
                  <el-input v-model="form.Towns" size="small" placeholder="请输入乡"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11" :offset="1">
                <el-form-item label-width="0px">
                  <el-input v-model="form.Village" size="small" placeholder="请输入村"></el-input>
                </el-form-item>
              </el-col>
            </el-col>
            <el-col :span="19" :offset="1">
              <el-form-item label="经纬度">
                <el-col :span="7">
                  <el-form-item label-width="0px">
                    <el-input v-model="form.Longitude" size="small" placeholder="请输入经度，例：116.393116933"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="7" :offset="1">
                  <el-form-item label-width="0px">
                    <el-input v-model="form.Latitude" size="small" placeholder="请输入纬度，例：39.90734137"></el-input>
                  </el-form-item>
                </el-col>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="排序">
                <el-input v-model="form.SortCode" size="small"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider content-position="left">规模</el-divider>
          <el-row>
            <el-col :span="9" :offset="1">
              <el-form-item label="危害体积">
                <el-input v-model="form.DangerVolume" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="危害面积">
                <el-input v-model="form.DangerArea" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="诱发因素">
                <el-input v-model="form.DangerFactor" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="9" :offset="1">
              <el-form-item label="发展趋势">
                <el-input v-model="form.DangerTrend" size="small"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="19" :offset="1">
              <el-form-item label="监测点情况说明">
                <el-input type="textarea" v-model="form.DangerInfo" size="small"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider content-position="left">备注信息</el-divider>
          <el-row>
            <el-col :span="19" :offset="1">
              <el-form-item label="备注">
                <el-input type="textarea" v-model="form.Marks" size="small" :rows="5"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="23" :offset="1">
              <el-form-item>
                <el-button type="primary" size="medium" @click="onSubmit">立即保存</el-button>
                <el-button type="primary" plain icon="el-icon-circle-close" size="medium" style="margin-left: 10px"
                  @click="$router.go(-1);">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="图片信息" name="tabImage">
          <form-images :images.sync="images" :detailid="form.Id" :formtype="formtype"></form-images>
        </el-tab-pane>
        <el-tab-pane label="文件信息" name="tabFile">
          <form-files :files.sync="files" :detailid="form.Id" :formtype="formtype"></form-files>
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>
<script>
import formImages from "./components/formImages";
import formFiles from "./components/formFiles";
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
import { addDanger, getDangerDetail, editDanger } from "@/api/base";
export default {
  data() {
    return {
      pageload: false,
      activeTabName: "tabBase",
      options: regionData,
      regionValue: [],
      DangerTypeData: [],
      DangerLevelData: [],
      form: {
        DangerCode: "",
        DangerName: "",
        DangerType: "",
        RockType: "",
        Longitude: "",
        Latitude: "",
        Height: "",
        DangerAspect: "",
        DangerSlope: "",
        DangerScale: "",
        DangerInfo: "",
        DangerArea: "",
        DangerVolume: "",
        DangerPeople: "",
        DangerHouse: "",
        DangerProperty: "",
        DangerFactor: "",
        Province: "",
        City: "",
        County: "",
        Towns: "",
        Village: "",
        Marks: "",
        DangerTrend: "",
        DangerLevel: "",
        Id: 0,
        SortCode: 0,
      },
      formRules: {
        DangerName: [
          { required: true, trigger: "blur", message: "请输入监测点名称" },
        ],
        DangerCode: [
          { required: true, trigger: "blur", message: "请输入监测点编号" },
        ],
        DangerType: [
          { required: true, trigger: "blur", message: "请选择灾害类型" },
        ],
        DangerLevel: [
          { required: true, trigger: "blur", message: "请选择灾害等级" },
        ],
      },
      files: [],
      images: [],
    };
  },
  components: {
    formImages,
    formFiles,
  },
  props: ["formtype"],
  methods: {
    onSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.formtype != "edit") {
            addDanger({
              hiddangerModel: this.form,
              files: this.files,
              images: this.images,
            })
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  setTimeout(() => {
                    this.$router.go(-1);
                  }, 500);
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          } else {
            editDanger(this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success("保存成功!");
                  setTimeout(() => {
                    this.$router.go(-1);
                  }, 500);
                } else {
                  this.$message.error("保存失败!" + res.msg);
                }
              })
              .catch((errmsg) => {
                this.$message.error("保存失败!" + errmsg);
              });
          }
        } else {
          return false;
        }
      });
    },
    regionHandleChange(value) {
      if (value.length == 3) {
        this.form.Province = CodeToText[value[0]];
        this.form.City = CodeToText[value[1]];
        this.form.County = CodeToText[value[2]];
      }
    },
  },
  created() {
    this.$store.dispatch("dictionary/getDangerType").then((data) => {
      this.DangerTypeData = data;
    });
    this.$store.dispatch("dictionary/getDangerLevel").then((data) => {
      this.DangerLevelData = data;
    });
    if (this.formtype == "edit") {
      this.pageload = true;
      var dangerId = this.$route.query.id;
      getDangerDetail(dangerId)
        .then((res) => {
          this.pageload = false;
          if (res.code == 0) {
            this.form = res.data.hiddangerModel;
            this.files = res.data.files;
            this.images = res.data.images;
            this.regionValue = [
              this.form.Province ? TextToCode[this.form.Province].code : '',
              this.form.City ? TextToCode[this.form.Province][this.form.City].code : '',
              this.form.County ? TextToCode[this.form.Province][this.form.City][this.form.County]
                .code : '',
            ];
          }
        })
        .catch((errmsg) => {
          this.pageload = true;
          this.$message.error(errmsg);
        });
    }
  },
};
</script>